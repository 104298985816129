/**
 * Used on the server-side to get the user object from the cookie
 */
import type { User } from "@/types.js";
import { GenericUserSession } from "@/shared/lib/UserSession.js";
import { NextjsClientStorage } from "@/nextjs/cookies.js";
import { retrieveTokens } from "@/shared/lib/util.js";

export const getUser = async (): Promise<User | null> => {
  const clientStorage = new NextjsClientStorage();
  const userSession = new GenericUserSession(clientStorage);
  const tokens = await retrieveTokens(clientStorage);
  const user = await userSession.get();
  if (!user || !tokens) return null;

  return {
    ...user!,
    idToken: tokens.id_token,
    accessToken: tokens.access_token,
    refreshToken: tokens.refresh_token ?? "",
  } as User;
};
