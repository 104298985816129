import type { AuthStorage, SessionData, UnknownObject, User } from "@/types.js";

type SameSiteOption = "strict" | "lax" | "none";

export interface SessionStorage {
  get(): SessionData;
  getUser(): User<UnknownObject> | null;
  set(data: Partial<SessionData>): void;
  setUser(data: User<UnknownObject> | null): void;
  clear(): void;
}

export type CookieStorageSettings = {
  httpOnly: boolean;
  secure: boolean;
  sameSite: SameSiteOption;
  expires: Date;
  path: string;
};

export const DEFAULT_COOKIE_DURATION = 60 * 15; // 15 minutes

export abstract class CookieStorage implements AuthStorage {
  protected settings: CookieStorageSettings;
  protected constructor(settings: Partial<CookieStorageSettings> = {}) {
    this.settings = {
      httpOnly: settings.httpOnly ?? true,
      secure: settings.secure ?? true,
      // the callback request comes the auth server
      // 'lax' ensures the code_verifier cookie is sent with the request
      sameSite: settings.sameSite ?? "lax",
      expires:
        settings.expires ??
        new Date(Date.now() + 1000 * DEFAULT_COOKIE_DURATION),
      path: settings.path ?? "/",
    };
  }
  abstract get(key: string): Promise<string | null>;
  abstract set(key: string, value: string): Promise<void>;
}
