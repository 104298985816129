import { GenericPublicClientPKCEProducer } from "@/services/PKCE.js";
import { OAuth2Client } from "oslo/oauth2";
import type {
  AuthStorage,
  Endpoints,
  OIDCTokenResponseBody,
  SessionData,
} from "@/types.js";
import type { AuthConfig } from "@/server/config.js";
import {
  exchangeTokens,
  getEndpointsWithOverrides,
  retrieveTokens,
  storeTokens,
} from "@/shared/lib/util.js";
import type { AuthenticationResolver, PKCEProducer } from "@/services/types.ts";
import { DEFAULT_AUTH_SERVER } from "@/constants.js";

export class ServerAuthenticationResolver implements AuthenticationResolver {
  private pkceProducer: PKCEProducer;
  private oauth2client: OAuth2Client | undefined;
  private endpoints: Endpoints | undefined;

  private constructor(
    readonly authConfig: AuthConfig,
    readonly storage: AuthStorage,
    readonly endpointOverrides?: Partial<Endpoints>,
  ) {
    this.pkceProducer = new GenericPublicClientPKCEProducer(storage);
  }
  validateExistingSession(): Promise<SessionData> {
    throw new Error("Method not implemented.");
  }

  get oauthServer(): string {
    return this.authConfig.oauthServer || DEFAULT_AUTH_SERVER;
  }

  async init(): Promise<this> {
    // resolve oauth config
    this.endpoints = await getEndpointsWithOverrides(
      this.oauthServer,
      this.endpointOverrides,
    );
    this.oauth2client = new OAuth2Client(
      this.authConfig.clientId,
      this.endpoints.auth,
      this.endpoints.token,
      {
        redirectURI: this.authConfig.redirectUrl,
      },
    );

    return this;
  }

  async tokenExchange(
    code: string,
    state: string,
  ): Promise<OIDCTokenResponseBody> {
    if (!this.oauth2client) await this.init();
    const codeVerifier = await this.pkceProducer.getCodeVerifier();
    if (!codeVerifier) throw new Error("Code verifier not found in storage");

    // exchange auth code for tokens
    const tokens = await exchangeTokens(
      code,
      state,
      this.pkceProducer,
      this.oauth2client!, // clean up types here to avoid the ! operator
      this.oauthServer,
      this.endpoints!, // clean up types here to avoid the ! operator
    );

    await storeTokens(this.storage, tokens);

    return tokens;
  }

  async getSessionData(): Promise<SessionData | null> {
    const storageData = await retrieveTokens(this.storage);

    if (!storageData) return null;

    return {
      authenticated: !!storageData.id_token,
      idToken: storageData.id_token,
      accessToken: storageData.access_token,
      refreshToken: storageData.refresh_token,
    };
  }

  static async build(
    authConfig: AuthConfig,
    storage: AuthStorage,
    endpointOverrides?: Partial<Endpoints>,
  ): Promise<AuthenticationResolver> {
    const resolver = new ServerAuthenticationResolver(
      authConfig,
      storage,
      endpointOverrides,
    );
    await resolver.init();

    return resolver;
  }
}
