import type { NextRequest } from "next/server.js";
import { NextResponse } from "next/server.js";
import { revalidatePath } from "next/cache.js";
import type { AuthConfig } from "@/nextjs/config.js";
import { resolveAuthConfig } from "@/nextjs/config.js";
import { loggers } from "@/lib/logger.js";
import {
  clearAuthCookies,
  NextjsClientStorage,
  NextjsCookieStorage,
} from "@/nextjs/cookies.js";
import { GenericPublicClientPKCEProducer } from "@/services/PKCE.js";
import { resolveOAuthAccessCode } from "@/server/login.js";
import { getUser } from "@/shared/lib/session.js";
import { resolveCallbackUrl } from "@/nextjs/utils.js";
import { GenericUserSession } from "@/shared/lib/UserSession.js";
import {
  TOKEN_EXCHANGE_SUCCESS_TEXT,
  TOKEN_EXCHANGE_TRIGGER_TEXT,
} from "@/constants.js";
import { serverTokenExchangeFromState } from "@/lib/oauth.js";
import { CodeVerifier } from "@/shared/lib/types.js";

const logger = loggers.nextjs.handlers.auth;

class AuthError extends Error {
  constructor(
    message: string,
    public readonly status: number = 401,
  ) {
    super(message);
    this.name = "AuthError";
  }
}

/**
 * create a code verifier and challenge for PKCE
 * saving the verifier in a cookie for later use
 * @returns {Promise<NextResponse>}
 */
async function handleChallenge(
  request: NextRequest,
  config: AuthConfig,
): Promise<NextResponse> {
  const cookieStorage = new NextjsCookieStorage(config.cookies?.tokens ?? {});
  const pkceProducer = new GenericPublicClientPKCEProducer(cookieStorage);

  const challenge = await pkceProducer.getCodeChallenge();
  const appUrl = request.nextUrl.searchParams.get("appUrl");
  if (appUrl) {
    cookieStorage.set(CodeVerifier.APP_URL, appUrl);
  }
  return NextResponse.json({ status: "success", challenge });
}

async function performTokenExchangeAndSetCookies(
  request: NextRequest,
  config: AuthConfig,
  code: string,
  state: string,
  appUrl: string,
) {
  const resolvedConfigs = resolveAuthConfig(config);
  const cookieStorage = new NextjsCookieStorage(resolvedConfigs.cookies.tokens);

  const callbackUrl = resolveCallbackUrl(resolvedConfigs, appUrl);
  try {
    await resolveOAuthAccessCode(code, state, cookieStorage, {
      ...resolvedConfigs,
      redirectUrl: callbackUrl,
    });
  } catch (error) {
    logger.error("Token exchange failed:", error);
    throw new AuthError("Failed to authenticate user", 401);
  }

  const user = await getUser(cookieStorage);
  if (!user) {
    throw new AuthError("Failed to get user info", 401);
  }

  const clientStorage = new NextjsClientStorage();
  const userSession = new GenericUserSession(clientStorage);
  userSession.set(user);
}
async function handleCallback(
  request: NextRequest,
  config: AuthConfig,
): Promise<NextResponse> {
  const resolvedConfigs = resolveAuthConfig(config);
  console.log("handleCallback", { request, resolvedConfigs });
  const code = request.nextUrl.searchParams.get("code");
  const state = request.nextUrl.searchParams.get("state") || "";
  if (!code || !state) throw new AuthError("Bad parameters", 400);

  // appUrl is passed from the client to the server in the query string
  // this is necessary because the server does not have access to the client's window.location.origin
  // and can not accurately determine the appUrl (specially if the app is behind a reverse proxy)
  const appUrl =
    request.cookies.get(CodeVerifier.APP_URL)?.value ||
    request.nextUrl.searchParams.get("appUrl");

  // If we have a code_verifier cookie and the appUrl, we can do a token exchange.
  // Otherwise, just render an empty page.
  // The initial redirect back from the auth server does not send cookies, because the redirect is from a 3rd-party domain.
  // The client will make an additional call to this route with cookies included, at which point we do the token exchange.
  console.log("handleCallback", {
    code,
    state,
    appUrl,
  });

  const codeVerifier = request.cookies.get(CodeVerifier.COOKIE_NAME);

  if (!codeVerifier || !appUrl) {
    console.log("handleCallback no code_verifier found", {
      state,
      serverTokenExchange: serverTokenExchangeFromState(`${state}`),
    });
    let response = new NextResponse(
      `<html><body><span style="display:none">${TOKEN_EXCHANGE_TRIGGER_TEXT}</span></body></html>`,
    );

    // in server-side token exchange mode we need to launch a page that will trigger the token exchange
    // from the same domain, allowing it access to the code_verifier cookie
    // we only need to do this in redirect mode, as the iframe already triggers a client-side token exchange
    // if no code-verifier cookie is found
    if (state && serverTokenExchangeFromState(state)) {
      console.log(
        "handleCallback serverTokenExchangeFromState, launching redirect page...",
        {
          requestUrl: request.url,
          configCallbackUrl: resolvedConfigs.callbackUrl,
        },
      );
      // we need to replace the URL with resolved config in case the server is hosted
      // behind a reverse proxy or load balancer
      const requestUrl = new URL(request.url);
      const fetchUrl = `${resolvedConfigs.callbackUrl}?${requestUrl.searchParams.toString()}&sameDomainServerTokenExchange=true`;
      response = new NextResponse(
        `<html>
            <body>
                <span style="display:none">
                    <script>
                        window.onload = function () {
                            const appUrl = globalThis.window?.location?.origin;
                            fetch('${fetchUrl}&appUrl=' + appUrl).then((response) => {
                                response.json().then((jsonResponse) => {
                                    if (jsonResponse.redirectUrl) {
                                        window.location.href = jsonResponse.redirectUrl;
                                    }
                                });
                            });
                        };
                    </script>
                </span>
            </body>
        </html>
        `,
      );
    }
    response.headers.set("Content-Type", "text/html; charset=utf-8");
    console.log(
      `handleCallback no code_verifier found, returning ${TOKEN_EXCHANGE_TRIGGER_TEXT}`,
    );
    return response;
  }

  await performTokenExchangeAndSetCookies(
    request,
    resolvedConfigs,
    code,
    state,
    appUrl,
  );

  if (request.url.includes("sameDomainServerTokenExchange=true")) {
    console.log(
      "handleCallback sameDomainServerTokenExchange = true, returnining redirectUrl",
      appUrl,
    );
    return NextResponse.json({
      status: "success",
      redirectUrl: appUrl,
    });
  }

  // this is the case where a 'normal' redirect is happening
  if (serverTokenExchangeFromState(state)) {
    console.log(
      "handleCallback serverTokenExchangeFromState, redirect to appUrl",
      appUrl,
    );
    if (!appUrl) {
      throw new Error("appUrl undefined. Cannot redirect.");
    }
    return NextResponse.redirect(`${appUrl}`);
  }
  // return an empty HTML response so the iframe doesn't show any response
  // in the short moment between the redirect and the parent window
  // acknowledging the redirect and closing the iframe
  const response = new NextResponse(
    `<html><span style="display:none">${TOKEN_EXCHANGE_SUCCESS_TEXT}</span></html>`,
  );
  response.headers.set("Content-Type", "text/html; charset=utf-8");
  return response;
}

/**
 * If redirectPath is an absolute path, return it as-is.
 * Otherwise for relative paths, append it to the current domain.
 * @param redirectPath
 * @returns
 */
const getAbsoluteRedirectPath = (
  redirectPath: string,
  currentBasePath: string,
) => new URL(redirectPath, currentBasePath).href;

export async function handleLogout(
  request: NextRequest,
  config: AuthConfig,
): Promise<NextResponse> {
  const resolvedConfigs = resolveAuthConfig(config);
  const defaultRedirectPath = resolvedConfigs.loginUrl ?? "/";
  const redirectTarget =
    new URL(request.url).searchParams.get("redirect") || defaultRedirectPath;

  const isAbsoluteRedirect = /^(https?:\/\/|www\.).+/i.test(redirectTarget);

  const appUrl = request.nextUrl.searchParams.get("appUrl");

  const finalRedirectUrl = isAbsoluteRedirect
    ? redirectTarget
    : getAbsoluteRedirectPath(
        redirectTarget,
        new URL(appUrl ?? request.url).origin,
      );

  const response = NextResponse.redirect(finalRedirectUrl);

  await clearAuthCookies(config);

  try {
    revalidatePath(isAbsoluteRedirect ? finalRedirectUrl : redirectTarget);
  } catch (error) {
    logger.warn("Failed to revalidate path after logout:", error);
  }

  return response;
}

/**
 * Creates an authentication handler for Next.js API routes
 *
 * Usage:
 * ```ts
 * // app/api/auth/[...civicauth]/route.ts
 * import { handler } from '@civic/auth/nextjs'
 * export const GET = handler({
 *   // optional config overrides
 * })
 * ```
 */
export const handler =
  (authConfig = {}) =>
  async (request: NextRequest): Promise<NextResponse> => {
    const config = resolveAuthConfig(authConfig);

    try {
      const pathname = request.nextUrl.pathname;
      const pathSegments = pathname.split("/");
      const lastSegment = pathSegments[pathSegments.length - 1];

      switch (lastSegment) {
        case "challenge":
          return await handleChallenge(request, config);
        case "callback":
          return await handleCallback(request, config);
        case "logout":
          return await handleLogout(request, config);
        default:
          throw new AuthError(`Invalid auth route: ${pathname}`, 404);
      }
    } catch (error) {
      logger.error("Auth handler error:", error);

      const status = error instanceof AuthError ? error.status : 500;
      const message =
        error instanceof Error ? error.message : "Authentication failed";

      const response = NextResponse.json({ error: message }, { status });

      clearAuthCookies(config);
      return response;
    }
  };
