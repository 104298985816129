/* eslint-disable turbo/no-undeclared-env-vars */
import type { NextConfig } from "next";
import { loggers } from "@/lib/logger.js";
import { withoutUndefined } from "@/utils.js";
import {
  CodeVerifier,
  type CookieConfig,
  OAuthTokens,
  type TokensCookieConfig,
} from "@/shared/lib/types.js";
import { DEFAULT_AUTH_SERVER } from "@/constants.js";

const logger = loggers.nextjs.handlers.auth;

export type CookiesConfigObject = {
  tokens: TokensCookieConfig;
  user: CookieConfig;
};

export type AuthConfigWithDefaults = {
  clientId: string;
  oauthServer: string;
  callbackUrl: string;
  loginUrl: string;
  logoutUrl: string;
  challengeUrl: string;
  include: string[];
  exclude: string[];
  cookies: CookiesConfigObject;
};

export type AuthConfig = Partial<AuthConfigWithDefaults>;

export type DefinedAuthConfig = AuthConfigWithDefaults;

const defaultServerSecure = !(process.env.NODE_ENV === "development");
/**
 * Default configuration values that will be used if not overridden
 */
export const defaultAuthConfig: Omit<AuthConfigWithDefaults, "clientId"> = {
  oauthServer: DEFAULT_AUTH_SERVER,
  callbackUrl: "/api/auth/callback",
  challengeUrl: "/api/auth/challenge",
  logoutUrl: "/api/auth/logout",
  loginUrl: "/",
  include: ["/*"],
  exclude: [],
  cookies: {
    tokens: {
      [OAuthTokens.ID_TOKEN]: {
        secure: defaultServerSecure,
        httpOnly: true,
        sameSite: "strict",
        path: "/",
      },
      [OAuthTokens.ACCESS_TOKEN]: {
        secure: defaultServerSecure,
        httpOnly: true,
        sameSite: "strict",
        path: "/",
      },
      [OAuthTokens.REFRESH_TOKEN]: {
        secure: defaultServerSecure,
        httpOnly: true,
        sameSite: "strict",
        path: "/",
      },
      [CodeVerifier.COOKIE_NAME]: {
        secure: defaultServerSecure,
        httpOnly: true,
        sameSite: "strict",
        path: "/",
      },
      [CodeVerifier.APP_URL]: {
        secure: defaultServerSecure,
        httpOnly: true,
        sameSite: "strict",
        path: "/",
      },
    },
    user: {
      secure: defaultServerSecure,
      httpOnly: false,
      sameSite: "strict",
      path: "/",
      maxAge: 60 * 60, // 1 hour
    },
  },
};

/**
 * Resolves the authentication configuration by combining:
 * 1. Default values
 * 2. Environment variables (set internally by the plugin)
 * 3. Explicitly passed configuration
 *
 * Note: Developers should not set _civic_auth_* environment variables directly.
 * Instead, pass configuration to the createCivicAuthPlugin in next.config.js:
 *
 * @example
 * ```js
 * // next.config.js
 * export default createCivicAuthPlugin({
 *   callbackUrl: '/custom/callback',
 * })
 * ```
 */
export const resolveAuthConfig = (
  config: AuthConfig = {},
): AuthConfigWithDefaults => {
  // Read configuration that was set by the plugin via environment variables
  const configFromEnv = withoutUndefined({
    clientId: process.env._civic_auth_client_id,
    oauthServer: process.env._civic_oauth_server,
    callbackUrl: process.env._civic_auth_callback_url,
    challengeUrl: process.env._civic_auth_challenge_url,
    loginUrl: process.env._civic_auth_login_url,
    logoutUrl: process.env._civic_auth_logout_url,
    include: process.env._civic_auth_includes?.split(","),
    exclude: process.env._civic_auth_excludes?.split(","),
    cookies: process.env._civic_auth_cookie_config
      ? JSON.parse(process.env._civic_auth_cookie_config)
      : undefined,
  }) as AuthConfig;
  const mergedConfig = {
    ...defaultAuthConfig,
    ...configFromEnv, // Apply plugin-set config
    ...config, // Override with directly passed config
    cookies: {
      tokens: {
        ...defaultAuthConfig.cookies.tokens,
        ...(configFromEnv?.cookies?.tokens || {}),
        ...(config.cookies?.tokens || {}),
      },
      user: {
        ...defaultAuthConfig.cookies.user,
        ...(configFromEnv?.cookies?.user || {}),
        ...(config.cookies?.user || {}),
      },
    },
  };

  logger.debug(
    "Config from environment:",
    JSON.stringify(configFromEnv, null, 2),
  );
  logger.debug("Resolved config:", JSON.stringify(mergedConfig, null, 2));
  if (mergedConfig.clientId === undefined) {
    throw new Error("Civic Auth client ID is required");
  }
  return mergedConfig as AuthConfigWithDefaults & { clientId: string };
};

/**
 * Creates a Next.js plugin that handles auth configuration.
 *
 * This is the main configuration point for the auth system.
 * Do not set _civic_auth_* environment variables directly - instead,
 * pass your configuration here:
 *
 * @example
 * ```js
 * // next.config.js
 * export default createCivicAuthPlugin({
 *   clientId: 'my-client-id',
 *   callbackUrl: '/custom/callback',
 *   loginUrl: '/custom/login',
 *   logoutUrl: '/custom/logout',
 *   include: ['/protected/*'],
 *   exclude: ['/public/*']
 * })
 * ```
 *
 * The plugin sets internal environment variables that are used by
 * the auth system. These variables should not be set manually.
 */
export const createCivicAuthPlugin = (
  authConfig: AuthConfig & Pick<Required<AuthConfig>, "clientId">,
) => {
  return (nextConfig?: NextConfig) => {
    logger.debug(
      "createCivicAuthPlugin nextConfig",
      JSON.stringify(nextConfig, null, 2),
    );
    const resolvedConfig = resolveAuthConfig({ ...authConfig });
    return {
      ...nextConfig,
      env: {
        ...nextConfig?.env,
        // Internal environment variables - do not set these manually
        _civic_auth_client_id: resolvedConfig.clientId,
        _civic_oauth_server: resolvedConfig.oauthServer,
        _civic_auth_callback_url: resolvedConfig.callbackUrl,
        _civic_auth_challenge_url: resolvedConfig.challengeUrl,
        _civic_auth_login_url: resolvedConfig.loginUrl,
        _civic_auth_logout_url: resolvedConfig.logoutUrl,
        _civic_auth_includes: resolvedConfig.include.join(","),
        _civic_auth_excludes: resolvedConfig.exclude.join(","),
        _civic_auth_cookie_config: JSON.stringify(resolvedConfig.cookies),
      },
    };
  };
};
