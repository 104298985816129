import type { AuthStorage, OIDCTokenResponseBody } from "@/types.js";
import { DEFAULT_AUTH_SERVER, DEFAULT_SCOPES } from "@/constants.js";
import { GenericAuthenticationInitiator } from "@/services/AuthenticationService.js";
import { GenericPublicClientPKCEProducer } from "@/services/PKCE.js";
import { ServerAuthenticationResolver } from "@/server/ServerAuthenticationResolver.js";
import type { AuthConfig } from "@/server/config.ts";
/**
 * Resolve an OAuth access code to a set of OIDC tokens
 * @param code The access code, typically from a query parameter in the redirect url
 * @param state The oauth random state string, used to distinguish between requests. Typically also passed in the redirect url
 * @param storage The place that this server uses to store session data (e.g. a cookie store)
 * @param config Oauth Server configuration
 */
export async function resolveOAuthAccessCode(
  code: string,
  state: string,
  storage: AuthStorage,
  config: AuthConfig,
): Promise<OIDCTokenResponseBody> {
  const authSessionService = await ServerAuthenticationResolver.build(
    {
      ...config,
      oauthServer: config.oauthServer ?? DEFAULT_AUTH_SERVER,
    },
    storage,
    config.endpointOverrides,
  );

  return authSessionService.tokenExchange(code, state);
}

export async function isLoggedIn(storage: AuthStorage): Promise<boolean> {
  return !!(await storage.get("id_token"));
}

export async function buildLoginUrl(
  config: Pick<AuthConfig, "clientId" | "redirectUrl"> &
    Partial<Pick<AuthConfig, "oauthServer">> & {
      scopes?: string[];
      state?: string;
      nonce?: string;
    },
  storage: AuthStorage,
): Promise<URL> {
  // generate a random state if not provided
  const state = config.state ?? Math.random().toString(36).substring(2);
  const scopes = config.scopes ?? DEFAULT_SCOPES;
  const pkceProducer = new GenericPublicClientPKCEProducer(storage);
  const authInitiator = new GenericAuthenticationInitiator({
    ...config,
    state,
    scopes,
    oauthServer: config.oauthServer ?? DEFAULT_AUTH_SERVER,
    // When retrieving the PKCE challenge on the server-side, we produce it and store it in the session
    pkceConsumer: pkceProducer,
  });

  return authInitiator.signIn();
}
