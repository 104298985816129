"use client";
/**
 * A very small context provider for the user object - it takes the user object from the cookie and provides it to the app.
 */
import React, { useEffect, useState } from "react";
import type { AuthProviderProps } from "@/shared/providers/AuthProvider.js";
import { AuthProvider } from "@/shared/providers/AuthProvider.js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { User } from "@/types.js";
import { resolveAuthConfig } from "@/nextjs/config.js";
import { resolveCallbackUrl } from "@/nextjs/utils.js";
import { ConfidentialClientPKCEConsumer } from "@/services/PKCE.js";
import { NextjsClientStorage } from "@/nextjs/cookies.js";
import { UserProvider } from "@/shared/providers/UserProvider.js";
import { OAuthTokens } from "@/shared/lib/types.js";
import { useUserCookie } from "@/nextjs/hooks/useUserCookie.js";
import { useTokenCookie } from "@/nextjs/hooks/index.js";

const queryClient = new QueryClient();

type NextCivicAuthProviderProps = Omit<AuthProviderProps, "clientId">;

const CivicNextAuthProviderInternal = ({
  children,
  ...props
}: NextCivicAuthProviderProps) => {
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const resolvedConfig = resolveAuthConfig();
  const { clientId, oauthServer, callbackUrl, challengeUrl, logoutUrl } =
    resolvedConfig;

  useEffect(() => {
    if (typeof globalThis.window !== "undefined") {
      const appUrl = globalThis.window.location.origin;
      setRedirectUrl(resolveCallbackUrl(resolvedConfig, appUrl));
    }
  }, [callbackUrl, resolvedConfig]);

  const user = useUserCookie();
  const idToken = useTokenCookie(OAuthTokens.ID_TOKEN);
  const combinedUser = user ? ({ ...(user || {}), idToken } as User) : null;
  const sessionData = {
    authenticated: !!user,
    ...(idToken ? { idToken } : {}),
  };
  const signOut = async (): Promise<void> => {
    if (props.onSignOut) {
      await props.onSignOut();
    }
    const appUrl = globalThis.window.location.origin;
    window.location.href = `${logoutUrl}?appUrl=${appUrl}`;
    return;
  };
  return (
    <AuthProvider
      {...props}
      redirectUrl={redirectUrl}
      config={{ oauthServer }}
      clientId={clientId}
      pkceConsumer={new ConfidentialClientPKCEConsumer(challengeUrl)}
      sessionData={sessionData}
    >
      <UserProvider
        storage={new NextjsClientStorage()}
        user={combinedUser}
        signOut={signOut}
      >
        {children}
      </UserProvider>
    </AuthProvider>
  );
};

const CivicNextAuthProvider = ({
  children,
  ...props
}: NextCivicAuthProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CivicNextAuthProviderInternal {...props}>
        {children}
      </CivicNextAuthProviderInternal>
    </QueryClientProvider>
  );
};

export { CivicNextAuthProvider, type NextCivicAuthProviderProps };
