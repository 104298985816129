"use client";
import { useEffect, useRef } from "react";
import { useRouter } from "next/navigation.js";
import { useQuery } from "@tanstack/react-query";
import { getWindowCookieValue } from "@/lib/cookies.js";
import type { EmptyObject, User } from "@/types.js";
import { UserStorage } from "@/shared/lib/types.js";

const getUserFromCookie = () =>
  getWindowCookieValue({
    key: UserStorage.USER,
    window: globalThis.window,
    parseJson: true,
  });

export const useUserCookie = <T extends EmptyObject>() => {
  const hasRunRef = useRef(false);
  const router = useRouter();

  const { data: user } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserFromCookie() as User<T> | null,
    refetchInterval: 2000,
    refetchIntervalInBackground: true,
    enabled: !hasRunRef.current,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (user) {
      if (!hasRunRef.current) {
        hasRunRef.current = true;
        router.refresh();
      }
    } else {
      hasRunRef.current = false;
    }
  }, [user, router]);

  return user ?? null;
};
