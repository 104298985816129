import type { SessionData, UnknownObject, User } from "@/types.js";
import type { AuthConfig } from "@/nextjs/config.js";
import { cookies } from "next/headers.js";
import { GenericUserSession } from "@/shared/lib/UserSession.js";
import { clearTokens } from "@/shared/lib/util.js";
import type {
  CodeVerifier,
  CookieConfig,
  OAuthTokens,
  TokensCookieConfig,
} from "@/shared/lib/types.js";
import {
  CookieStorage,
  type CookieStorageSettings,
} from "@/shared/lib/storage.js";

/**
 * Creates HTTP-only cookies for authentication tokens
 */
const createTokenCookies = (
  response: Response,
  sessionData: SessionData,
  config: AuthConfig,
) => {
  const maxAge = sessionData.expiresIn ?? 3600;
  const cookieOptions = {
    ...config.cookies?.tokens,
    maxAge,
  };

  if (sessionData.accessToken) {
    setCookie(response, "access_token", sessionData.accessToken, {
      ...cookieOptions,
      httpOnly: true,
    });
  }

  if (sessionData.idToken) {
    setCookie(response, "id_token", sessionData.idToken, {
      ...cookieOptions,
      httpOnly: true,
    });
  }

  if (sessionData.refreshToken) {
    setCookie(response, "refresh_token", sessionData.refreshToken, {
      ...cookieOptions,
      httpOnly: true,
    });
  }
};

const setCookie = (
  response: Response,
  key: string,
  value: string,
  cookieData: CookieConfig,
) => {
  response.headers.set(
    "Set-Cookie",
    `${key}=${value}; Path=${cookieData.path}; Domain=${cookieData.domain}; Max-Age=${cookieData.maxAge}; Secure; HttpOnly; SameSite=${cookieData.sameSite}`,
  );
};

/**
 * Creates a client-readable cookie with user info
 */
const createUserInfoCookie = (
  response: Response,
  user: User<UnknownObject> | null,
  sessionData: SessionData,
  config: AuthConfig,
) => {
  if (!user) {
    // unset the "user" cookie
    setCookie(response, "user", "", {
      ...config.cookies?.user,
      maxAge: 0,
    });
    return;
  }
  const maxAge = sessionData.expiresIn ?? 3600;

  // TODO select fields to include in the user cookie
  const frontendUser = {
    ...user,
  };

  // TODO make call to get user info from the
  // auth server /userinfo endpoint when it's available
  // then add to the default claims above

  setCookie(response, "user", JSON.stringify(frontendUser), {
    ...config.cookies?.user,
    maxAge,
  });
};

/**
 * Clears all authentication cookies
 */
const clearAuthCookies = async (config: AuthConfig) => {
  // clear session, and tokens
  const cookieStorage = new NextjsCookieStorage(config.cookies?.tokens);
  await clearTokens(cookieStorage);

  // clear user
  const clientStorage = new NextjsClientStorage();
  const userSession = new GenericUserSession(clientStorage);
  await userSession.set(null);
};

type KeySetter = OAuthTokens | CodeVerifier;
class NextjsCookieStorage extends CookieStorage {
  constructor(readonly config: Partial<TokensCookieConfig> = {}) {
    super({
      secure: true,
      httpOnly: true,
    });
  }

  async get(key: string): Promise<string | null> {
    const cookieStore = await cookies();
    return cookieStore.get(key)?.value || null;
  }

  async set(key: KeySetter, value: string): Promise<void> {
    const cookieStore = await cookies();
    const cookieSettings = this.config?.[key as KeySetter] || {
      ...this.settings,
    };
    cookieStore.set(key, value, cookieSettings);
  }
}

class NextjsClientStorage extends CookieStorage {
  constructor(config: Partial<CookieStorageSettings> = {}) {
    super({
      ...config,
      secure: false,
      httpOnly: false,
    });
  }

  async get(key: string): Promise<string | null> {
    const cookieStore = await cookies();
    return cookieStore.get(key)?.value || null;
  }

  async set(key: string, value: string): Promise<void> {
    const cookieStore = await cookies();
    cookieStore.set(key, value, this.settings);
  }
}

export {
  createTokenCookies,
  createUserInfoCookie,
  clearAuthCookies,
  NextjsCookieStorage,
  NextjsClientStorage,
};
