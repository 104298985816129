const getWindowCookieValue = ({
  key,
  window,
  parseJson = false,
}: {
  key: string;
  window: Window;
  parseJson?: boolean;
}) => {
  const cookie = window.document.cookie;
  if (!cookie) return null;
  const cookies = cookie.split(";");
  for (const c of cookies) {
    const [name, value] = c.trim().split("=");
    if (value && name === key) {
      try {
        const decodeURIComponentValue = decodeURIComponent(value);
        return parseJson
          ? JSON.parse(decodeURIComponentValue)
          : decodeURIComponentValue;
      } catch {
        return value;
      }
    }
  }
  return null;
};
export { getWindowCookieValue };
